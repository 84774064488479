import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import {
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, Dialog, DialogTitle } from "@mui/material";
import React from "react";
import { useForm, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { TaxesEntity } from "../../../../@types/createDocument";
import { useTranslation } from "react-i18next";
import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import CloseModalButton from "../../../../assets/icon/close/CloseModalButton";

interface TaxesModalProps {
  isShowModal: boolean;
  showModalMethod: VoidFunction;
  taxesAppend?: (obj: TaxesEntity) => void;
  taxUpdate?: (index: number, obj: TaxesEntity) => void;
  index?: number;
  initialValues?: TaxesEntity;
}

const TaxesModal: React.FC<TaxesModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    isShowModal,
    taxesAppend,
    showModalMethod,
    taxUpdate,
    index,
    initialValues,
  } = props;

  const taxesSchema = Yup.object().shape({
    baseAmount: Yup.number()
      .required(t("baseAmount_is_required") as string)
      .typeError(t("baseAmount_must_be_number") as string),
    vat: Yup.number()
      .required(t("tax_is_required") as string)
      .typeError(t("tax_must_be_number") as string),
  });

  const methods = useForm<TaxesEntity>({
    resolver: yupResolver(taxesSchema),
    defaultValues: initialValues,
  });

  const { handleSubmit } = methods;

  const addTax = (values: TaxesEntity) => {
    index === undefined ? taxesAppend!(values) : taxUpdate!(index, values);
    showModalMethod();
  };

  return (
    <Dialog
      open={isShowModal}
      onClose={showModalMethod}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={"md"}
      fullWidth
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(addTax)}>
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" sx={{ textAlign: "left", my: 1 }}>
              {t(`add_tax`)}
            </Typography>

            <IconButton
              sx={{
                height: "10px",
                width: "8px",
              }}
              onClick={showModalMethod}
            >
              <CloseModalButton />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <RHFTextField label={t("baseAmount")} name={"baseAmount"} />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField name={"vat"} label={t("tax_amount_not_%")} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            {index === undefined ? t("add_tax") : t("update_tax")}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default TaxesModal;
