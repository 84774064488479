import { Button, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { emailState } from "../../../features/pageSlice";
import { ButtonStyle } from "../../../theme/overrides/Button";
import LogoutIcon from "../../../assets/icon/logout";

export default function LogoutButton() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function handleLogout() {
    localStorage.removeItem("code");
    localStorage.removeItem("date");
    dispatch(emailState());
  }

  return (
    <Tooltip title={t(`logout`)} placement="top">
      <IconButton size="small" onClick={handleLogout}>
        <LogoutIcon />
      </IconButton>
    </Tooltip>
  );
}
