import { useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BACKEND } from "../../../constants/settings";
import { PostFileAsync } from "../../../common/httpRequests";
import { UploadMultiFile } from "../upload";

interface UploadDocumentBoxProps {
  getDocuments: VoidFunction;
  showModalMethod: VoidFunction;
  docType: string;
}

function UploadDocumentBox(props: UploadDocumentBoxProps) {
  const { getDocuments, showModalMethod, docType } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [files, setFiles] = useState<any[]>([]);

  const handleDropMultiFile = React.useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...files, ...newFiles]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile: any) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const maxFiles = 10;
    const names = files.map((value) => value.name);
    const duplicates = names.filter(
      (name, index, arr) => arr.indexOf(name) !== index
    );
    for (let i = 0; i < duplicates.length; i++) {
      if (duplicates[i] === files[i].name) {
        setIsLoading(false);
        return enqueueSnackbar(t("duplicateNotAllowed"), {
          variant: "error",
        });
      }
    }
    if (files.length > maxFiles) {
      setIsLoading(false);
      return enqueueSnackbar(t("limit"), { variant: "error" });
    }
    const currentSupplierId = window.location.pathname.split("/")[1];
    const formData = new FormData();
    formData.append("Supplierid", currentSupplierId);
    files.forEach(function (file) {
      formData.append(`documents`, file, file.name);
    });

    const res = await PostFileAsync(
      `${BACKEND}/api/vendorService/suppliers/upload${docType}`,
      formData,
      localStorage.getItem("code"),
      currentSupplierId,
      false
    );
    try {
      const errorFiles = res.data.documents.filter(
        (file) => file.hasError === true
      );

      handleRemoveAllFiles();
      if (!errorFiles.length) {
        enqueueSnackbar(t("successUpload"), {
          variant: "success",
        });
        getDocuments();
        setIsLoading(false);
        showModalMethod();
      } else {
        enqueueSnackbar(
          `${t("errorUpload")} ${errorFiles
            .map((file) => file.fileName)
            .join(", ")} \n ${errorFiles.map((file) => file.message)}`,
          { variant: "error" }
        );
        setIsLoading(false);
        showModalMethod();
      }
    } catch (error) {
      console.log("Error-->", error);
      setIsLoading(false);
      showModalMethod();
    }
  };

  return (
    <UploadMultiFile
      loading={isLoading}
      multiple={true}
      files={files}
      onDrop={handleDropMultiFile}
      onRemove={handleRemoveFile}
      onRemoveAll={handleRemoveAllFiles}
      onUpload={() => onSubmit()}
      handleCloseModal={() => showModalMethod()}
    />
  );
}

export default UploadDocumentBox;
