import { useDropzone } from "react-dropzone";
// @mui
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// type
import { UploadMultiFileProps } from "./type";
//
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { ButtonStyle } from "../../../theme/overrides/Button";
import BlockContent from "./BlockContent";
import MultiFilePreview from "./MultiFilePreview";
import RejectionFiles from "./RejectionFiles";
import CloseModalButton from "../../../assets/icon/close/CloseModalButton";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  padding: theme.spacing(2, 2),
  borderRadius: "8px",
  backgroundColor: "primary.dark",
  // border: `1px dashed ${"primary.dark"}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

// ----------------------------------------------------------------------

export default function UploadMultiFile({
  loading,
  error,
  validator,
  showPreview = false,
  files,
  onUpload,
  onRemove,
  onRemoveAll,
  helperText,
  sx,
  handleCloseModal,
  ...other
}: UploadMultiFileProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    ...other,
    accept: {
      accepted: ["image/*", "application/pdf", "application/xml", ".xml"],
    },
  });

  const { t } = useTranslation();
  const fileMessage = `a_maximum_of_10_files_are_allowed_in_a_single_upload`;

  return (
    <Box sx={{ ...sx }}>
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {t("upload_by_device")}
          <IconButton sx={{ height: 30, width: 30 }} onClick={handleCloseModal}>
            <CloseModalButton />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: "error.main",
              borderColor: "main.light",
              bgcolor: "primary.light",
              height: "200px",
            }),
          }}
        >
          <input {...getInputProps()} />
          <BlockContent fileName={undefined} fileMessage={fileMessage} />
        </DropZoneStyle>
        {fileRejections.length > 0 && (
          <RejectionFiles fileRejections={fileRejections} />
        )}
        <MultiFilePreview
          files={files}
          showPreview={showPreview}
          onRemove={onRemove}
          loading={false}
        />
        {files.length > 0 && (
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ marginTop: "10px" }}
            alignItems="center"
            spacing={1.5}
          >
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Button
                sx={{
                  textAlign: "center",
                  marginRight: 2,
                  fontSize: "16px",
                  width: "160px",
                  height: "48px",
                }}
                size="small"
                variant="outlined"
                onClick={onRemoveAll}
              >
                {t("Cancel")}
              </Button>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                size="small"
                sx={{
                  width: "160px",
                  height: "48px",
                  fontSize: "16px",
                }}
                variant="contained"
                onClick={onUpload}
              >
                {t("Upload")}
              </LoadingButton>
            </Box>
          </Stack>
        )}
        {helperText && helperText}
      </DialogContent>
    </Box>
  );
}
