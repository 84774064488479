import { Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RHFUploadSingleFile } from "../../../components/hook-form";
import { useFormContext } from "react-hook-form";

interface UploadedDocumentProps {
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
}

const UploadDocument = ({ fileName, setFileName }: UploadedDocumentProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFileName(file.name);
      if (file) {
        setValue(
          "uploadedDocument",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("upload_document")}</Typography>
        <Grid item xs={12}>
          <RHFUploadSingleFile
            name="uploadedDocument"
            onDrop={handleDrop}
            fileName={fileName}
            accept={{
              "image/jpeg": [],
              "image/png": [],
              "application/pdf": [],
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UploadDocument;
