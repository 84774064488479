import { IconButton } from "@mui/material";
import MenuPopover from "./../pages/supplier_page/header/MenuPopover";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
type Props = {
  actions: React.ReactNode;
  open?: HTMLElement | null;
  onClose?: VoidFunction;
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
};

export default function TableMoreMenu({
  actions,
  open,
  onClose,
  onOpen,
}: Props) {
  return (
    <>
      <IconButton size="small" onClick={onOpen}>
        <MoreHorizIcon />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 200,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
            "& svg": { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
