import { format, getTime, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number, dateFormat?: string) {
  return format(new Date(date), dateFormat ?? "yyyy-MM-dd");
}

export function fDateTime(
  date: Date | string | number,
  formatStr: string = "dd MMM yyyy p"
) {
  return format(new Date(date), formatStr);
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), "dd/MM/yyyy p");
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
