import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import RequestImage from "../../../assets/request-sent.svg";
import { BACKEND } from "../../../constants/settings";
import { uploadState } from "../../../features/pageSlice";
import { ButtonStyle } from "../../../theme/overrides/Button";
import { PostAsync } from "../../../common/httpRequests";
import CodeInput from "./CodeInput";

const schema = yup
  .object({
    code1: yup.number().integer().max(9).min(0).required(),
    code2: yup.number().integer().max(9).min(0).required(),
    code3: yup.number().integer().max(9).min(0).required(),
    code4: yup.number().integer().max(9).min(0).required(),
    code5: yup.number().integer().max(9).min(0).required(),
    code6: yup.number().integer().max(9).min(0).required(),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

export default function CodeForm(props: any) {
  const { email, handleSendEmail, setIsLoading } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deletedField, setDeletedField] = useState(0);
  const dispatch = useDispatch();
  const mode = useSelector((state: any) => state.mode.value);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const handleVerifyCode = async (data) => {
    const code =
      data.code1.toString() +
      data.code2.toString() +
      data.code3.toString() +
      data.code4.toString() +
      data.code5.toString() +
      data.code6.toString();
    const currentSupplierId = window.location.pathname.split("/")[1];
    const request = {
      id: currentSupplierId,
      code: code,
    };
    setIsLoading(true);
    const res = await PostAsync(
      `${BACKEND}/api/vendorservice/suppliers/login`,
      request
    );
    setIsLoading(false);
    if (res.status === 200) {
      localStorage.setItem("code", code);
      localStorage.setItem("supplierId", currentSupplierId);
      localStorage.setItem(
        "date",
        (Number(new Date().getTime()) / 3.6e6).toString()
      );
      dispatch(uploadState());
    } else {
      return enqueueSnackbar(t("email.wrongCode"), {
        variant: "error",
      });
    }
  };

  const handleDelete = (event) => {
    if (event.code === "Backspace") {
      let step = Number(event.target.id.slice(-1));
      if (step !== 1 && deletedField === step) {
        (
          ReactDOM.findDOMNode(
            document.querySelector(`#code${step - 1}`)
          ) as HTMLElement
        )?.focus();
      } else if (step !== 1) {
        setDeletedField(step);
      }
    }
  };

  const handleFill = (value) => {
    let step = Number(value.target.id.slice(-1));
    let input = value.target.value;
    if (input.length >= 2) {
      value.target.value = Number(input[input.length - 1]);
    }
    if (value.target.value && step !== 6) {
      (
        ReactDOM.findDOMNode(
          document.querySelector(`#code${step + 1}`)
        ) as HTMLElement
      )?.focus();
    } else if (value.target.value) {
      (
        ReactDOM.findDOMNode(
          document.querySelector(`#codeConfirm`)
        ) as HTMLElement
      )?.focus();
      document.querySelector("form")?.requestSubmit();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    let paste = event.clipboardData.getData("text");
    const separatedNumbers = paste.toString().split("");
    if (separatedNumbers.length >= 6) {
      [...Array(6)].forEach((_, i) => {
        const currentCodeInput = document.querySelector(`#code${i + 1}`);
        if (currentCodeInput) {
          (currentCodeInput as HTMLInputElement).value = separatedNumbers[i];
          (ReactDOM.findDOMNode(currentCodeInput) as HTMLElement)?.focus();
        }
      });
    }
    (
      ReactDOM.findDOMNode(
        document.querySelector(`#codeConfirm`)
      ) as HTMLElement
    )?.focus();
    document.querySelector("form")?.requestSubmit();
  };

  return (
    <Box display={"flex"} flexDirection={"column"} maxWidth="500px">
      <Box component="img" src={RequestImage} alignSelf="center"></Box>
      <form
        action="/"
        method="POST"
        onSubmit={handleSubmit((d) => handleVerifyCode(d))}
      >
        <DialogTitle
          fontWeight={600}
          fontSize={"32px"}
          display={"flex"}
          justifyContent={"center"}
        >
          {t("email.checkEmail")}
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Typography color={mode === "light" ? "#7c8a96" : "#919fab"}>
            {t("email.checkEmailCtx1")} {email}
            {t("email.checkEmailCtx2")}
          </Typography>
        </DialogContent>
        <DialogContent>
          <Typography color={mode === "light" ? "#7c8a96" : "#919fab"}>
            {errors.code1?.message}
            {errors.code2?.message}
            {errors.code3?.message}
            {errors.code4?.message}
            {errors.code5?.message}
            {errors.code6?.message}
          </Typography>
          <Box display="flex" justifyContent={"space-around"}>
            <CodeInput
              register={register}
              position="code1"
              handleFill={handleFill}
              handlePaste={handlePaste}
            />
            <CodeInput
              register={register}
              position="code2"
              handleFill={handleFill}
              handleDelete={handleDelete}
            />
            <CodeInput
              register={register}
              position="code3"
              handleFill={handleFill}
              handleDelete={handleDelete}
            />
            <CodeInput
              register={register}
              position="code4"
              handleFill={handleFill}
              handleDelete={handleDelete}
            />
            <CodeInput
              register={register}
              position="code5"
              handleFill={handleFill}
              handleDelete={handleDelete}
            />
            <CodeInput
              register={register}
              position="code6"
              handleFill={handleFill}
              handleDelete={handleDelete}
            />
          </Box>
          <DialogActions
            sx={{
              paddingRight: 0,
              paddingLeft: 0,
              paddingTop: 2,
            }}
          >
            <Button
              fullWidth
              id="codeConfirm"
              type="submit"
              color="secondary"
              variant="contained"
              onSubmit={handleSendEmail}
            >
              {t("email.confirm")}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        paddingLeft={3}
      >
        {t("email.resend")}{" "}
        <Button
          sx={{
            padding: 0,
            paddingLeft: 1,
            textTransform: "none",
          }}
          onClick={handleSendEmail}
        >
          {t("email.resendLink")}
        </Button>
      </Typography>
    </Box>
  );
}
