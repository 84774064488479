// @mui
import { TableRow, TableCell } from "@mui/material";
//
import EmptyContent from "../EmptyContent";

import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean;
};

export default function TableNoData({ isNotFound }: Props) {
  const { t } = useTranslation();

  //   const handleTranslate = (key: string): string => translate(key) ?? "";

  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={16}>
          <EmptyContent
            title={t("no_data")}
            sx={{
              "& span.MuiBox-root": { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
