// @mui
import { Box, Typography, Stack } from "@mui/material";
// assets

//hooks
import { Button } from "@mui/material";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileUploadIllustration from "../../assets/fileUploadIllustration";

// ----------------------------------------------------------------------

interface BlockContentProps {
  fileName: string;
  fileMessage: string;
}

export default function BlockContent({
  fileName,
  fileMessage,
}: BlockContentProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: "column", md: "column" }}
      sx={{ width: 1, textAlign: { xs: "center", md: "left" } }}
    >
      <FileUploadIllustration sx={{ width: 150 }} />
      <Box sx={{ p: 3 }}>
        {fileName ? (
          fileName
        ) : (
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textAlign: "center" }}
          >
            {t("Drop your files here or browse through your machine.")}
            &nbsp;
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{
            color: "text.primary",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {t(fileMessage)}
          &nbsp;
        </Typography>
        <Box sx={{ textAlign: "center", padding: "10px" }}>
          <Button
            variant="contained"
            sx={{
              textAlign: "center",
              backgroundColor: theme.palette.secondary.dark,
              fontSize: "17px",
              minWidth: "120px",
            }}
            size="small"
          >
            {t(`browse`)}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
}
