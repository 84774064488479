import { FormHelperText, InputLabel, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  useFormContext,
  Control,
  FieldValues,
  Controller,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

interface RHFDatePickerProps {
  name: string;
  label?: string | null;
}

const RHFDatePicker = (props: RHFDatePickerProps) => {
  const { name, label, ...other } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control as Control<FieldValues, any>}
        render={({
          field: { value, onChange, ref },
          fieldState: { error },
        }) => (
          <>
            <InputLabel
              htmlFor={"country-select-demo"}
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                // transform: "translate(0, 1.5px) scale(0.75)",
                color: theme.palette.action.active,
              }}
            >
              {label}
            </InputLabel>
            <DatePicker
              value={value}
              inputRef={ref}
              onChange={onChange}
              slotProps={{
                day: {
                  sx: {
                    "&.MuiPickersDay-root": {
                      backgroundColor: theme.palette.action.disabledBackground,
                    },
                  },
                },

                textField: {
                  size: "small",
                  error: !!error,

                  sx: {
                    width: "100%",
                    "& .MuiInputBase-input": {
                      ...(error && {
                        backgroundColor: theme.palette.error.light,
                        borderColor: theme.palette.error.dark,
                        color: theme.palette.error.dark,
                        "&::placeholder": {
                          opacity: 1,
                          color: theme.palette.error.dark,
                        },
                        "&:focus": {
                          borderColor: theme.palette.error.dark,
                          boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
                        },
                        // ...sx,
                      }),
                      backgroundColor: error
                        ? theme.palette.error.light
                        : theme.palette.background.paper,

                      // ...(startAdornment && {
                      //   padding: "5px 26px 5px 32px",
                      // }),
                      // ...sx,
                    },
                  },
                },
              }}
            />
            {error && (
              <FormHelperText error sx={{ textAlign: "left", mt: -0.2 }}>
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </LocalizationProvider>
  );
};

export default RHFDatePicker;
