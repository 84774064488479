import { TableCell, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableMoreMenu from "../../../../components/TableMoreMenu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import Label from "../../../../components/Label";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import { Tooltip } from "@mui/material";

const DocumentTableRow = ({ row, handleOpenPayment, handleOpenERP }) => {
  const { t }: any = useTranslation();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
      <TableCell align="left">
        <Tooltip title={row.fileName} placement="top">
          <Typography
            variant="body2"
            noWrap
            sx={{ textOverflow: "ellipsis", width: 300 }}
          >
            {row.fileName}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (row.status == "Default" && "warning") ||
            (row.status == "Extracted" && "success") ||
            (row.status == "Failed" && "error") ||
            (row.status == "Error" && "error") ||
            (row.status == "NeedReview" && "warning") ||
            (row.status == "Reviewed" && "success") ||
            (row.status == "Duplicate" && "info") ||
            "error"
          }
          sx={{ textTransform: "capitalize" }}
        >
          {t(row.status)}
        </Label>
      </TableCell>
      {/* <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={row.isIntegrated ? "success" : "error"}
          sx={{ textTransform: "capitalize" }}
        >
          {t(row.isIntegrated ? t("Yes") : t("No"))}
        </Label>
      </TableCell> */}
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            row.erpDetail?.status === "Approved"
              ? "success"
              : row.erpDetail?.status === "Rejected"
              ? "error"
              : "warning"
          }
          sx={{ textTransform: "capitalize" }}
        >
          {t(row.erpDetail?.status ?? "Pending")}
        </Label>
      </TableCell>
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            row.paymentDetail?.status === "UnPaid"
              ? "info"
              : row.paymentDetail?.status === "Paid"
              ? "success"
              : "warning"
          }
          sx={{ textTransform: "capitalize" }}
        >
          {t(row.paymentDetail?.status ?? "Pending")}
        </Label>
      </TableCell>
      <TableCell align="left">
        <Typography variant="subtitle2">
          {format(new Date(row.creationDateTime), "dd MMMM yyyy")}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                disabled={!row.erpDetail}
                onClick={() => {
                  handleOpenERP(row);
                  handleCloseMenu();
                }}
              >
                {t("View ERP details")}
              </MenuItem>
              <MenuItem
                disabled={!row.paymentDetail}
                onClick={() => {
                  handleOpenPayment(row);
                  handleCloseMenu();
                }}
              >
                {t("View payment details")}
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default DocumentTableRow;
