import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonStyle } from "../../../theme/overrides/Button";
import NewTextField from "../../../components/form/TextField";

export default function EmailForm(props: any) {
  const { handleSendEmail } = props;
  const { t } = useTranslation();
  const mode = useSelector((state: any) => state.mode.value);

  return (
    <form
      action="/"
      method="POST"
      onSubmit={handleSendEmail}
      style={{
        maxWidth: "500px",
      }}
    >
      <DialogTitle variant="h4" fontWeight={700}>
        {t("email.enterEmail")}
      </DialogTitle>
      <DialogContent>
        <Typography
          gutterBottom
          color={mode === "light" ? "#7c8a96" : "#919fab"}
        >
          {t("email.enterEmailCtx")}
        </Typography>
        <NewTextField
          autoFocus
          required
          margin="dense"
          id="email"
          label={"Email"}
          type="email"
          fullWidth
          variant="outlined"
          InputLabelProps={{
            style: {
              color: mode === "light" ? "#a6b1bb" : "white",
            },
          }}
          sx={{
            fieldset: {
              borderColor: mode === "light" ? "#a6b1bb" : "#30373f",
              [`&.${inputLabelClasses.shrink}`]: {
                borderColor: mode === "light" ? "#a6b1bb" : "#30373f",
              },
              "&:focus": {
                borderColor: mode === "light" ? "#a6b1bb" : "#30373f",
              },
            },
          }}
        />
        <DialogActions
          sx={{
            paddingRight: 0,
            paddingLeft: 0,
            paddingTop: 2,
          }}
        >
          <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            onSubmit={handleSendEmail}
          >
            {t("email.sendCode")}
          </Button>
        </DialogActions>
      </DialogContent>
    </form>
  );
}
