import { createSlice } from "@reduxjs/toolkit";

export const pageModeSlice = createSlice({
  name: "mode",
  initialState: {
    value: localStorage.getItem("mode") || "light",
  },
  reducers: {
    switchMode: (state) => {
      const newMode = state.value === "light" ? "dark" : "light";
      localStorage.setItem("mode", newMode);
      state.value = newMode;
    },
  },
});

export const { switchMode } = pageModeSlice.actions;

export default pageModeSlice.reducer;
