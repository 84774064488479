import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseModalButton from "../assets/icon/close/CloseModalButton";
import DeleteIcon from "../assets/icon/delete";
import { useTranslation } from "react-i18next";

interface DeleteConfirmationProps {
  isShowModal: boolean;
  showModalMethod: VoidFunction;
  dialogTitle: string;
  deleteEntity: string;
  deleteWarning: string;
  deleteLoader?: boolean;
  handleDelete: VoidFunction;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationProps> = (props) => {
  const {
    isShowModal,
    showModalMethod,
    dialogTitle,
    deleteEntity,
    deleteWarning,
    deleteLoader,
    handleDelete,
  } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={isShowModal}
      onClose={showModalMethod}
      fullWidth
      maxWidth={"sm"}
      scroll="body"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h5">
          {dialogTitle}?
        </Typography>
        <IconButton sx={{ height: 30, width: 30 }} onClick={showModalMethod}>
          <CloseModalButton />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", pt: 1 }}
            align="center"
          >
            {`${t(`delete_approval`)} ${deleteEntity}?`}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.primary", fontWeight: "bold" }}
            align="center"
          >
            {t(deleteWarning)}
          </Typography>
        </Stack>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid
            item
            md={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => showModalMethod()}
              size="medium"
              variant="outlined"
            >
              {t("cancel_button")}
            </Button>

            {deleteLoader ? (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={deleteLoader}
                onClick={handleDelete}
                loadingPosition="start"
                sx={{ ml: 1 }}
                size="medium"
                startIcon={
                  <DeleteIcon customColor="white" height={20} width={20} />
                }
              >
                {t("delete")}
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                variant="contained"
                onClick={handleDelete}
                sx={{ ml: 1 }}
                size="medium"
                startIcon={
                  <DeleteIcon customColor="white" height={20} width={20} />
                }
              >
                {t("delete")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
