import axios from "axios";

export async function PostAsync(baseUrl, body, shouldUseToken = false) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  // if (shouldUseToken) {
  //     let accessToken = await ValidateAccessToken();
  //     headers["Authorization"] = `Bearer ${accessToken}`;
  // }
  return await axios.post(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem("supplierId");
        localStorage.removeItem("code");
        window.location.reload("/");
      }
      return error.response;
    }
  );
}

export async function GetAsync(baseUrl, headers_ = {}) {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...headers_,
  };

  // if (shouldUseToken) {
  //     let accessToken = await ValidateAccessToken();
  //     headers["Authorization"] = `Bearer ${accessToken}`;
  // }

  return await axios.get(baseUrl, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem("supplierId");
        localStorage.removeItem("code");
        window.location.reload("/");
      }
      return error.response;
    }
  );
}

export async function PostFileAsync(
  baseUrl,
  body,
  code,
  id,
  shouldUseToken = false
) {
  let headers = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    code: code,
    supplierId: id,
  };

  // if (shouldUseToken) {
  //   let accessToken = await ValidateAccessToken();
  //   headers["Authorization"] = `Bearer ${accessToken}`;
  // }

  return await axios.post(baseUrl, body, { headers }).then(
    (res) => {
      return res;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem("supplierId");
        localStorage.removeItem("code");
        window.location.reload("/");
      }
      return error.response;
    }
  );
}
