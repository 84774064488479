import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND } from "../constants/settings";
import { uploadState } from "../features/pageSlice";
import { GetAsync, PostAsync } from "../common/httpRequests";
import EmailPageContainer from "../pages/email_send_page/skeleton/EmailPageContainer";
import SupplierPageContainer from "../pages/supplier_page/SupplierPageContainer";
import ThemeProvider from "./Theme";
import ThemeSettings from "../components/settings";
import { SettingsProvider } from "../contexts/SettingsContext";

export default function ThemeSetter() {
  const [allowed, setAllowed] = useState<boolean | "">("");
  const [isLoading, setIsLoading] = useState(false);
  const page = useSelector((state: any) => state.pageState.value);
  const dispatch = useDispatch();
  const fetchSupplierData = async () => {
    const currentSupplierId = window.location.pathname.split("/")[1];
    setIsLoading(true);
    try {
      if (currentSupplierId) {
        const response = await GetAsync(
          `${BACKEND}/api/vendorservice/suppliers/${currentSupplierId}`,
          {
            supplierId: currentSupplierId,
            code: localStorage.getItem("code"),
          }
        );
        if ([201, 200, 204].includes(response.status)) {
          setAllowed(true);
          return response.data;
        } else {
          setAllowed(false);
        }
      } else {
        setAllowed(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const currentSupplierId = window.location.pathname.split("/")[1];
    const checkExpiredCode = async () => {
      if (
        localStorage.getItem("date") &&
        new Date().getTime() / 3.6e6 - Number(localStorage.getItem("date")) >= 1
      ) {
        localStorage.clear();
      } else if (localStorage.length > 0 && localStorage.getItem("code")) {
        const request = {
          id: currentSupplierId,
          code: localStorage.getItem("code"),
        };
        const res = await PostAsync(
          `${BACKEND}/api/vendorservice/Suppliers/login`,
          request
        );

        if (res.status === 200) {
          dispatch(uploadState());
        }
      }
    };
    try {
      checkExpiredCode();
    } finally {
      setIsLoading(false);
    }
  }, [allowed]);

  const pagePermissionSwitch = {
    0: (
      <EmailPageContainer
        fetchSupplierData={fetchSupplierData}
        allowed={allowed}
        setIsLoading={setIsLoading}
      />
    ),
    1: (
      <EmailPageContainer
        fetchSupplierData={fetchSupplierData}
        allowed={allowed}
        setIsLoading={setIsLoading}
      />
    ),
    2: (
      <SupplierPageContainer
        allowed={allowed}
        fetchSupplierData={fetchSupplierData}
      />
    ),
  };

  return (
    <SettingsProvider>
      <ThemeProvider>
        <ThemeSettings>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            {pagePermissionSwitch[page]}
          </SnackbarProvider>
        </ThemeSettings>
      </ThemeProvider>
    </SettingsProvider>
  );
}
