import {
  Checkbox,
  MenuItem,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import ItemsModal from "./ItemsModal";
import { ItemsEntity } from "../../../../@types/createDocument";
import { useTranslation } from "react-i18next";
import DeleteConfirmationDialog from "../../../../components/DeleteConfirmationDialog";
import TableMoreMenu from "../../../../components/TableMoreMenu";
import EditIcon from "../../../../assets/icon/edit";
import DeleteIcon from "../../../../assets/icon/delete";

interface ItemsTableRowProps {
  index: number;
  itemUpdate: (index: number, obj: ItemsEntity) => void;
  itemsRemove: (index?: number | number[]) => void;
  onSelectRow: VoidFunction;
  row: ItemsEntity;
  selected: boolean;
}

const ItemsTableRow: React.FC<ItemsTableRowProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { row, itemsRemove, index, selected, onSelectRow, itemUpdate } = props;

  const [isItemsModalVisible, setIsItemsModalVisible] = useState(false);
  const [isDeleteItemDialogVisible, setIsDeleteItemDialogVisible] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleShowItemsModal = () => {
    setIsItemsModalVisible(!isItemsModalVisible);
  };
  function handleShowDeleteItemDialog() {
    setIsDeleteItemDialogVisible(!isDeleteItemDialogVisible);
  }

  function deleteItem() {
    itemsRemove(index);
  }

  return (
    <TableRow>
      {isItemsModalVisible && (
        <ItemsModal
          isShowModal={isItemsModalVisible}
          showModalMethod={handleShowItemsModal}
          itemUpdate={itemUpdate}
          index={index}
          initialValues={row}
        />
      )}
      {isDeleteItemDialogVisible && (
        <DeleteConfirmationDialog
          isShowModal={isDeleteItemDialogVisible}
          showModalMethod={handleShowDeleteItemDialog}
          dialogTitle={t("delete_item")}
          deleteEntity={t("item")}
          deleteWarning={""}
          handleDelete={deleteItem}
        />
      )}
      <TableCell>
        <Checkbox checked={selected} onClick={onSelectRow} size="small" />
      </TableCell>
      <TableCell>{row?.referenceCode}</TableCell>
      <TableCell>{row?.description}</TableCell>
      <TableCell>{row?.unitPrice}</TableCell>
      <TableCell>{row?.quantity}</TableCell>
      <TableCell>{row?.vat}</TableCell>
      <TableCell>{row?.discount}</TableCell>
      <TableCell>{row?.deliveryNote}</TableCell>
      <TableCell>{row?.poNumber}</TableCell>
      <TableCell>
        <TableMoreMenu
          open={anchorEl}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  handleShowItemsModal();
                  handleCloseMenu();
                }}
              >
                <EditIcon
                  height={20}
                  width={20}
                  sx={{
                    height: "auto",
                    width: "auto",
                  }}
                  customColor={theme.palette?.action.active}
                />
                {t("update_item")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleShowDeleteItemDialog();
                  handleCloseMenu();
                }}
              >
                <DeleteIcon
                  height={20}
                  width={20}
                  sx={{
                    height: "auto",
                    width: "auto",
                  }}
                  customColor={theme.palette?.action.active}
                />
                {t("delete_item")}
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default ItemsTableRow;
