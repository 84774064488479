import { LoadingButton } from "@mui/lab";
import { Button, Grid, Step, StepLabel, Stepper } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { FormProvider } from "../../../components/hook-form";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateDocumentDto, ItemsEntity } from "../../../@types/createDocument";
import { PostAsync, PostFileAsync } from "../../../common/httpRequests";
import { BACKEND } from "../../../constants/settings";
import DocumentInformation from "./DocumentInformation";
import CustomerInformation from "./CustomerInformation";
import Items from "./ItemsInformation/Items";
import Taxes from "./TaxesInformation/Taxes";
import VendorInformation from "./VendorInformation";
import UploadDocument from "./UploadDocument";
import { fDate } from "../../../utils/formatDateTime";
import { isAfter, isBefore } from "date-fns";

interface CreateDocumentFormProps {
  showModalMethod: VoidFunction;
  getDocuments: () => Promise<void>;
}

const defaultValues: CreateDocumentDto = {
  documentNumber: "",
  documentClass: "",
  poNumber: "",
  documentDate: null,
  dueDate: null,
  totalWithoutTax: null,
  // totalTax: null,
  deliveryNote: "",
  customerCompanyName: "",
  customerCompanyID: "",
  customerCompanyAddress: "",
  customerEmail: "",
  vendorCompanyName: "",
  vendorCompanyID: "",
  vendorCompanyAddress: "",
  vendorEmail: "",
  logoURL: "",
  items: [],
  taxes: [],
  uploadedDocument: "",
};

function getSteps() {
  return [
    "document_information",
    "customer_information",
    "my_information",
    "items_information",
    "taxes_information",
    "upload_document",
  ];
}

const CreateDocumentForm: React.FC<CreateDocumentFormProps> = ({
  showModalMethod,
  getDocuments,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitting2, setIsSubmitting2] = useState(false);
  const code = localStorage.getItem("code");
  const supplierId = localStorage.getItem("supplierId");
  const [fileName, setFileName] = useState("");
  const steps = getSteps();

  const createDocumentSchema = [
    Yup.object().shape({
      documentNumber: Yup.string().required(
        t("documentNumber_is_required") as string
      ),
      documentClass: Yup.string().required(
        t("documentClass_is_required") as string
      ),
      poNumber: Yup.string().when(
        "documentClass",
        ([documentClass], schema) => {
          return documentClass === "DeliveryNote"
            ? schema.required(t("poNumber_is_required") as string)
            : schema.notRequired();
        }
      ),
      documentDate: Yup.date()
        .test(
          "documentDate_less_than_dueDate",
          t("documentDate_should_be_less_than_dueDate") as string,
          (value, context): boolean => {
            if (context.parent.documentClass === "Invoice") {
              if (isBefore(value!, context.parent.dueDate)) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }
        )
        .required(t("documentDate_is_required") as string)
        .typeError(t("add_valid_date") as string),
      dueDate: Yup.date()
        .when("documentClass", ([documentClass], schema) => {
          return documentClass === "Invoice"
            ? schema.required(t("dueDate_is_required") as string)
            : schema.notRequired();
        })
        .test(
          "dueDate_less_than_documentDate",
          t("dueDate_should_be_greater_than_documentDate") as string,
          function (value, context): boolean {
            if (context.parent.documentClass === "Invoice") {
              if (isAfter(value!, context.parent.documentDate)) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }
        )
        .typeError(t("add_valid_date") as string)
        .nullable(),
      totalWithoutTax: Yup.number()
        .required(t("totalWithoutTax_is_required") as string)
        .typeError(t("totalWithoutTax_is_required") as string),
      deliveryNote: Yup.string(),
      // .when(
      //   "documentClass",
      //   ([documentClass], schema) => {
      //     return documentClass === "Invoice"
      //       ? schema.required(t("deliverNote_is_required") as string)
      //       : schema.notRequired();
      //   }
      // ),
    }),

    // Customer
    Yup.object({
      customerCompanyName: Yup.string().required(
        t("customerCompanyName_is_required") as string
      ),
      customerCompanyID: Yup.string().required(
        t("customerCompanyID_is_required") as string
      ),
      customerCompanyAddress: Yup.string().required(
        t("customerCompanyAddress_is_required") as string
      ),
      customerEmail: Yup.string()
        .email(t("email_invalid") as string)
        .required(t("customerEmail_is_required") as string),
    }),
    // Vendor
    Yup.object({
      vendorCompanyName: Yup.string().required(
        t("companyName_is_required") as string
      ),
      vendorCompanyID: Yup.string().required(
        t("companyID_is_required") as string
      ),
      vendorCompanyAddress: Yup.string().required(
        t("companyAddress_is_required") as string
      ),
      vendorEmail: Yup.string()
        .email(t("email_invalid") as string)
        .required(t("email_is_required") as string),
      logoURL: Yup.string(),
    }),
    // Items
    Yup.object({
      items: Yup.array()
        .of(
          Yup.object({
            referenceCode: Yup.string().required(
              t("referenceCode_is_required") as string
            ),
            description: Yup.string().required(
              t("description_is_required") as string
            ),
            unitPrice: Yup.number().required(
              t("unitPrice_is_required") as string
            ),
            quantity: Yup.number().required(
              t("quantity_is_required") as string
            ),

            vat: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            discount: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            deliveryNote: Yup.string(),
            poNumber: Yup.string(),
          })
        )
        .min(1, t("atleast_one_item_is_required") as string),
    }),
    // Taxes
    Yup.object({
      taxes: Yup.array()
        .defined()
        .of(
          Yup.object({
            baseAmount: Yup.number().required("baseAmount_is_required"),
            vat: Yup.number().required("vat_is_required"),
          })
        ),
      // .min(1, t("atleast_one_tax_is_required") as string),
    }),
    // Upload document
    Yup.object({
      uploadedDocument: Yup.mixed().test(
        "required",
        t("document_is_required") as string,
        (file) => {
          if (file) return true;
          return false;
        }
      ),
    }),
  ];

  const currentValidationSchema = createDocumentSchema[activeStep];

  const methods = useForm<CreateDocumentDto>({
    resolver: yupResolver(currentValidationSchema),
    defaultValues,
    shouldUnregister: false,
    mode: "onChange",
  });

  const { handleSubmit, control, trigger, reset } = methods;

  const {
    append: itemsAppend,
    remove: itemsRemove,
    fields: itemsField,
    update: itemUpdate,
  } = useFieldArray({ name: "items", control });

  const {
    append: taxesAppend,
    remove: taxesRemove,
    fields: taxesField,
    update: taxUpdate,
  } = useFieldArray({ name: "taxes", control });

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    reset(defaultValues);
    setActiveStep(0);
  };
  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <DocumentInformation />;
      case 1:
        return <CustomerInformation />;
      case 2:
        return <VendorInformation />;
      case 3:
        return (
          <Items {...{ itemsField, itemsAppend, itemsRemove, itemUpdate }} />
        );
      case 4:
        return (
          <Taxes {...{ taxesField, taxesAppend, taxesRemove, taxUpdate }} />
        );
      case 5:
        return <UploadDocument {...{ fileName, setFileName }} />;
      default:
        return "unknown step";
    }
  }

  function createFormData(values: CreateDocumentDto) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      if (key === "documentDate" || key === "dueDate") {
        if (value) {
          formData.append(`createDocumentDto.${key}`, fDate(value));
        }
      } else if (key === "items" || key === "taxes") {
        value.forEach((item, index: number) => {
          for (const [arrKey, arrVal] of Object.entries(item)) {
            formData.append(
              `createDocumentDto.${key}[${index}].${arrKey}`,
              arrVal as string
            );
          }
        });
      } else if (key === "uploadedDocument") {
        formData.append(`createDocumentDto.uploadedDocument`, value, fileName);
      } else {
        formData.append(`createDocumentDto.${key}`, value);
      }
    }
    return formData;
  }

  async function createDocument(values: CreateDocumentDto) {
    setIsSubmitting(true);
    const formData = createFormData(values);
    await PostFileAsync(
      `${BACKEND}/api/vendorService/Form`,
      formData,
      code,
      supplierId
    )
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(t("document_created_successfully"), {
            variant: "success",
          });
          setIsSubmitting(false);
          showModalMethod();
          getDocuments();
        } else {
          enqueueSnackbar(res?.data?.Detail, { variant: "error" });
          setIsSubmitting(false);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar(t("something_went_wrong"), {
          variant: "error",
        });
        setIsSubmitting(false);
      });
  }

  async function createNewDocument(values: CreateDocumentDto) {
    const formData = createFormData(values);
    setIsSubmitting2(true);
    await PostFileAsync(
      `${BACKEND}/api/vendorService/Form`,
      formData,
      code,
      supplierId
    )
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(t("document_created_successfully"), {
            variant: "success",
          });
          setIsSubmitting2(false);
          handleReset();
          getDocuments();
        } else {
          enqueueSnackbar(res?.data?.Detail, { variant: "error" });
          setIsSubmitting2(false);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar(t("something_went_wrong"), {
          variant: "error",
        });
        setIsSubmitting2(false);
      });
  }

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps: any = {};
          const stepProps: any = {};
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{t(step)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <FormProvider methods={methods}>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            {getStepContent(activeStep)}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ mr: 2 }}
            >
              {t("back")}
            </Button>

            {activeStep === steps.length - 1 ? (
              <>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isSubmitting2}
                  disabled={isSubmitting}
                  onClick={handleSubmit(createNewDocument)}
                  sx={{ mr: 1 }}
                >
                  {t("submit_and_create_new_document")}
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting2}
                  loading={isSubmitting}
                  onClick={handleSubmit(createDocument)}
                >
                  {t("submit")}
                </LoadingButton>
              </>
            ) : (
              <Button variant="contained" color="primary" onClick={handleNext}>
                {t("next")}
              </Button>
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </div>
  );
};

export default CreateDocumentForm;
