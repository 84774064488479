import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format as formatD } from "date-fns";
import { SelectChangeEvent, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import DocumentTableRow from "./TableRow";
import PaymentModal from "../modal/PaymentModal";
import ERPModal from "../modal/ERPModal";
import MyTablePagination from "../TablePagination";
import FileIcon from "../../../../assets/icon/file";
import TableNoData from "../../../../components/table/TableNoData";
import { DocumentsEntity } from "../../../../@types/documents";
import { Skeleton } from "@mui/material";
import { Controller } from "../../SupplierPageContainer";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string;
  type?: string;
  icon?: any;
}

const columns: Column[] = [
  {
    id: "fileName",
    label: "Document name",
    minWidth: 170,
    icon: <FileIcon height={20} width={20} />,
  },
  { id: "status", label: "Status", minWidth: 100 },
  // { id: "isIntegrated", label: "Created in ERP", minWidth: 100 },
  {
    id: "erpDetail.status",
    label: "Approval Process",
    minWidth: 100,
    type: "chip",
  },
  {
    id: "paymentDetail.status",
    label: "Payment status",
    minWidth: 100,
    type: "chip",
  },
  {
    id: "creationDateTime",
    label: "Created on",
    minWidth: 170,
    format: (value: any) => formatD(new Date(value), "dd MMMM yyyy"),
  },
];

interface ColumnGroupProps {
  rows: DocumentsEntity[] | null | undefined;
  isLoading: boolean;
  controller: Controller;
  setController: React.Dispatch<React.SetStateAction<Controller>>;
  totalRecords: number;
}

export default function ColumnGroupingTable(props: ColumnGroupProps) {
  const { rows, isLoading, controller, setController, totalRecords } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const handleOpenERP = (row) => {
    setSelectedRow(row);
    setOpen1(true);
  };
  const handleCloseERP = () => {
    setSelectedRow({});
    setOpen1(false);
  };

  const handleClose = () => {
    setSelectedRow({});
    setOpen(false);
  };
  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  function generate(element: React.ReactElement) {
    return [0, 1, 2, 3, 4, 5].map((value) =>
      React.cloneElement(element, {
        key: value,
      })
    );
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setController({ ...controller, page: newPage });
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<any>) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const theme = useTheme();

  return (
    <>
      <PaymentModal
        open={open}
        handleClose={handleClose}
        selectedRow={selectedRow}
      />
      <ERPModal
        open={open1}
        handleClose={handleCloseERP}
        selectedRow={selectedRow}
      />
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                color: "text.greyLight",
              }}
            >
              {columns.map((column) => {
                return (
                  <TableCell key={column.id} align={column.align}>
                    <Typography
                      variant="subtitle1"
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                    >
                      {column.icon && column.icon}
                      {t(column.label)}
                    </Typography>
                  </TableCell>
                );
              })}
              <TableCell align="left">
                <Typography variant="subtitle1">{t("Actions")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? generate(
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Skeleton
                        sx={{ marginLeft: 1.5 }}
                        width="100%"
                        animation="wave"
                        variant="text"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="normal">
                      <Skeleton width="100%" animation="wave" variant="text" />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="normal">
                      <Skeleton width="100%" animation="wave" variant="text" />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="normal">
                      <Skeleton width="100%" animation="wave" variant="text" />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="normal">
                      <Skeleton width="100%" animation="wave" variant="text" />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="normal">
                      <Skeleton width="100%" animation="wave" variant="text" />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="normal">
                      <Skeleton width="100%" animation="wave" variant="text" />
                    </TableCell>
                  </TableRow>
                )
              : rows?.map((row, index) => (
                  <DocumentTableRow
                    row={row}
                    key={index}
                    handleOpenPayment={handleOpen}
                    handleOpenERP={handleOpenERP}
                  />
                ))}
            <TableNoData
              isNotFound={rows?.length === 0 && isLoading === false}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <MyTablePagination
        rowsPerPageOptions={[10, 25, 100]}
        count={totalRecords}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("of")} ${count}`
        }
        labelRowsPerPage={t("Rows per page")}
      />
    </>
  );
}
