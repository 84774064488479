import {
  AlertTitle,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import TaxesModal from "./TaxesModal";
import TaxesTableRow from "./TaxesTableRow";
import { Alert } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { TaxesEntity } from "../../../../@types/createDocument";
import { useTranslation } from "react-i18next";
import DeleteConfirmationDialog from "../../../../components/DeleteConfirmationDialog";
import PlusIcon from "../../../../assets/icon/plus";
import DeleteIcon from "../../../../assets/icon/delete";
import TableNoData from "../../../../components/table/TableNoData";
import TableHeadCustom from "../../../../components/tableHeadCustom";
import TableSelectedActions from "../../../../components/TableSelectedActions";
import useTable from "../../../../hooks/useTable";

interface TaxesProps {
  taxesField: TaxesEntity[];
  taxesAppend: (obj: TaxesEntity) => void;
  taxesRemove: (index?: number | number[]) => void;
  taxUpdate: (index: number, obj: TaxesEntity) => void;
}

const TABLE_HEAD = ["baseAmount", "tax", "actions"];

const Taxes: React.FC<TaxesProps> = (props) => {
  const { taxesField, taxesAppend, taxesRemove, taxUpdate } = props;
  const {
    order,
    orderBy,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
  } = useTable();
  const { t } = useTranslation();
  const [isTaxesModalVisible, setIsTaxesModalVisible] = useState(false);
  const [isDeleteTaxDialogVisible, setIsDeleteTaxDialogVisible] =
    useState(false);

  const handleShowItemsModal = () => {
    setIsTaxesModalVisible(!isTaxesModalVisible);
  };

  function handleShowDeleteTaxDialog() {
    setIsDeleteTaxDialogVisible(!isDeleteTaxDialogVisible);
  }

  function deleteItem() {
    const taxesToRemove: number[] = [];
    taxesField.forEach((item, taxesIndex) => {
      selected.forEach((select, selectedIndex) => {
        if (select === item.id) {
          taxesToRemove.push(taxesIndex);
        }
      });
    });
    taxesRemove(taxesToRemove);
    handleShowDeleteTaxDialog();
    setSelected([]);
  }

  const theme = useTheme();
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const taxes = watch("taxes");

  return (
    <Grid container spacing={1} sx={{ mt: 2 }}>
      {isTaxesModalVisible && (
        <TaxesModal
          isShowModal={isTaxesModalVisible}
          showModalMethod={handleShowItemsModal}
          taxesAppend={taxesAppend}
        />
      )}
      {isDeleteTaxDialogVisible && (
        <DeleteConfirmationDialog
          isShowModal={isDeleteTaxDialogVisible}
          showModalMethod={handleShowDeleteTaxDialog}
          dialogTitle={
            selected.length > 1 ? t("delete_taxes") : t("delete_tax")
          }
          deleteEntity={t("tax")}
          deleteWarning={""}
          handleDelete={deleteItem}
        />
      )}

      <Grid item xs={4}>
        {!!errors.taxes && taxes.length === 0 && (
          <Alert severity="error">
            <AlertTitle>{t("Information")}</AlertTitle>
            {t(`${errors.taxes.message}`) as string}
          </Alert>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h6">{t("taxes")}</Typography>
        <Button
          variant="contained"
          onClick={handleShowItemsModal}
          startIcon={<PlusIcon customColor="white" height={20} width={20} />}
        >
          {t("add_tax")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer sx={{ minWidth: 800, position: "relative" }}>
          {selected.length > 0 && (
            <TableSelectedActions
              numSelected={selected?.length}
              rowCount={taxesField?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  taxesField?.map((row: TaxesEntity) => row.id!)
                )
              }
              actions={
                <Box
                  sx={{
                    px: 3,
                    cursor: "pointer",
                  }}
                >
                  <Tooltip title={t("delete")} placement="top">
                    <IconButton
                      size="small"
                      onClick={handleShowDeleteTaxDialog}
                    >
                      <DeleteIcon
                        height={20}
                        width={20}
                        customColor={theme.palette.action.active}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
          )}
          <Table size="small">
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD.map((item, index) => ({
                id: item,
                label: t(item),
                align: "left",
              }))}
              rowCount={taxesField?.length}
              numSelected={selected?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  taxesField?.map((row: TaxesEntity) => row.id!)
                )
              }
            />
            <TableBody>
              {taxesField.map((item, index) => (
                <TaxesTableRow
                  key={item.id}
                  row={item}
                  index={index}
                  taxesRemove={taxesRemove}
                  taxUpdate={taxUpdate}
                  onSelectRow={() => onSelectRow(item.id!)}
                  selected={selected.includes(item.id!)}
                />
              ))}
            </TableBody>
            <TableNoData isNotFound={taxesField.length === 0} />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Taxes;
