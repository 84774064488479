import { configureStore } from "@reduxjs/toolkit";
import modeReducer from "./features/pageMode";
import pageReducer from "./features/pageSlice";

export default configureStore({
  reducer: {
    pageState: pageReducer,
    mode: modeReducer,
  },
});
