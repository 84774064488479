import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RHFTextField } from "../../../components/hook-form";
import InfoIcon from "../../../assets/icon/info";

const VendorInformation = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const subSection = {
    pb: 2,
    pt: 1,
    mt: 2.5,
    pr: 2,
    ml: -1,
    borderRadius: "8px",
    background: theme.palette.background.neutral,
  };

  return (
    <Grid container spacing={2} sx={subSection}>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <InfoIcon customColor={theme.palette.text.primary} />
        <Typography variant="body1" sx={{ ml: 1 }}>
          {t(`please_enter_your_information`)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <RHFTextField name={"vendorCompanyName"} label={t("companyName")} />
      </Grid>
      <Grid item xs={6}>
        <RHFTextField name={"vendorCompanyID"} label={t("companyID")} />
      </Grid>
      <Grid item xs={6}>
        <RHFTextField
          name={"vendorCompanyAddress"}
          label={t("companyAddress")}
        />
      </Grid>

      <Grid item xs={6}>
        <RHFTextField name={"vendorEmail"} label={t("emailAddress")} />
      </Grid>
      <Grid item xs={6}>
        <RHFTextField name={"logoURL"} label={t("logo_url")} />
      </Grid>
    </Grid>
  );
};

export default VendorInformation;
