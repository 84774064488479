import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import ItemsTableRow from "./ItemsTableRow";
import ItemsModal from "./ItemsModal";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import { ItemsEntity } from "../../../../@types/createDocument";
import useTable from "../../../../hooks/useTable";
import { useTranslation } from "react-i18next";
import DeleteConfirmationDialog from "../../../../components/DeleteConfirmationDialog";
import PlusIcon from "../../../../assets/icon/plus";
import TableSelectedActions from "../../../../components/TableSelectedActions";
import DeleteIcon from "../../../../assets/icon/delete";
import TableHeadCustom from "../../../../components/tableHeadCustom";
import TableNoData from "../../../../components/table/TableNoData";

interface ItemsProps {
  itemsField: ItemsEntity[];
  itemsAppend: (obj: ItemsEntity) => void;
  itemsRemove: (index?: number | number[]) => void;
  itemUpdate: (index: number, obj: ItemsEntity) => void;
}

const TABLE_HEAD = [
  "referenceCode",
  "description",
  "unitPrice",
  "quantity",
  "tax",
  "discount",
  "deliveryNote",
  "poNumber",
  "actions",
];

const Items: React.FC<ItemsProps> = (props) => {
  const { itemsAppend, itemsField, itemsRemove, itemUpdate } = props;
  const {
    order,
    orderBy,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
  } = useTable();
  const { t } = useTranslation();
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const items = watch("items");
  const [isItemsModalVisible, setIsItemsModalVisible] = useState(false);
  const [isDeleteItemDialogVisible, setIsDeleteItemDialogVisible] =
    useState(false);
  const handleShowItemsModal = () => {
    setIsItemsModalVisible(!isItemsModalVisible);
  };
  function handleShowDeleteItemDialog() {
    setIsDeleteItemDialogVisible(!isDeleteItemDialogVisible);
  }

  function deleteItem() {
    const itemsToRemove: number[] = [];
    itemsField.forEach((item, itemsIndex) => {
      selected.forEach((select, selectedIndex) => {
        if (select === item.id) {
          itemsToRemove.push(itemsIndex);
        }
      });
    });
    itemsRemove(itemsToRemove);
    handleShowDeleteItemDialog();
    setSelected([]);
  }

  const theme = useTheme();

  const subSection = {
    pb: 2,
    pt: 1,
    mt: 2.5,
    pr: 2,
    ml: -1,
    borderRadius: "8px",
    background: theme.palette.background.neutral,
  };

  return (
    <Grid container spacing={1} sx={{ mt: 2 }}>
      {isItemsModalVisible && (
        <ItemsModal
          isShowModal={isItemsModalVisible}
          showModalMethod={handleShowItemsModal}
          itemsAppend={itemsAppend}
        />
      )}
      {isDeleteItemDialogVisible && (
        <DeleteConfirmationDialog
          isShowModal={isDeleteItemDialogVisible}
          showModalMethod={handleShowDeleteItemDialog}
          dialogTitle={
            selected.length > 1 ? t("delete_items") : t("delete_item")
          }
          deleteEntity={t("item")}
          deleteWarning={""}
          handleDelete={deleteItem}
        />
      )}
      <Grid item xs={4}>
        {!!errors.items && items.length === 0 && (
          <Alert severity="error">
            <AlertTitle>{t("Information")}</AlertTitle>
            {t(`${errors.items.message}`) as string}
          </Alert>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h6">Items</Typography>
        <Button
          variant="contained"
          onClick={handleShowItemsModal}
          startIcon={<PlusIcon customColor="white" height={20} width={20} />}
        >
          {t("add_item")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer sx={{ minWidth: 800, position: "relative" }}>
          {selected.length > 0 && (
            <TableSelectedActions
              numSelected={selected?.length}
              rowCount={itemsField?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  itemsField?.map((row: ItemsEntity) => row.id!)
                )
              }
              actions={
                <Box
                  sx={{
                    px: 3,
                    cursor: "pointer",
                  }}
                >
                  <Tooltip title={t("delete")} placement="top">
                    <IconButton
                      size="small"
                      onClick={handleShowDeleteItemDialog}
                    >
                      <DeleteIcon
                        height={20}
                        width={20}
                        customColor={theme.palette.action.active}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
          )}
          <Table size="small">
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD.map((item, index) => ({
                id: item,
                label: t(item),
                align: "left",
              }))}
              rowCount={itemsField?.length}
              numSelected={selected?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  itemsField?.map((row: ItemsEntity) => row.id!)
                )
              }
            />
            <TableBody>
              {itemsField.map((item, index) => (
                <ItemsTableRow
                  key={item.id}
                  row={item}
                  index={index}
                  itemsRemove={itemsRemove}
                  itemUpdate={itemUpdate}
                  onSelectRow={() => onSelectRow(item.id!)}
                  selected={selected.includes(item.id!)}
                />
              ))}
            </TableBody>
            <TableNoData isNotFound={itemsField.length === 0} />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Items;
