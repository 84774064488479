import {
  Checkbox,
  MenuItem,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import TaxesModal from "./TaxesModal";
import { useTranslation } from "react-i18next";
import { TaxesEntity } from "../../../../@types/createDocument";
import TableMoreMenu from "../../../../components/TableMoreMenu";
import EditIcon from "../../../../assets/icon/edit";
import DeleteIcon from "../../../../assets/icon/delete";
import DeleteConfirmationDialog from "../../../../components/DeleteConfirmationDialog";

interface TaxesTableRowProps {
  index: number;
  taxUpdate: (index: number, obj: TaxesEntity) => void;
  taxesRemove: (index?: number | number[]) => void;
  onSelectRow: VoidFunction;
  row: TaxesEntity;
  selected: boolean;
}

const TaxesTableRow: React.FC<TaxesTableRowProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { row, taxesRemove, index, selected, onSelectRow, taxUpdate } = props;

  const [isTaxesModalVisible, setIsTaxesModalVisible] = useState(false);
  const [isDeleteTaxDialogVisible, setIsDeleteTaxDialogVisible] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleShowTaxesModal = () => {
    setIsTaxesModalVisible(!isTaxesModalVisible);
  };
  function handleShowDeleteTaxDialog() {
    setIsDeleteTaxDialogVisible(!isDeleteTaxDialogVisible);
  }

  function deleteTax() {
    taxesRemove(index);
  }

  return (
    <TableRow>
      {isTaxesModalVisible && (
        <TaxesModal
          isShowModal={isTaxesModalVisible}
          showModalMethod={handleShowTaxesModal}
          taxUpdate={taxUpdate}
          index={index}
          initialValues={row}
        />
      )}
      {isDeleteTaxDialogVisible && (
        <DeleteConfirmationDialog
          isShowModal={isDeleteTaxDialogVisible}
          showModalMethod={handleShowDeleteTaxDialog}
          dialogTitle={t("delete_tax")}
          deleteEntity={t("tax")}
          deleteWarning={""}
          handleDelete={deleteTax}
        />
      )}
      <TableCell>
        <Checkbox checked={selected} onClick={onSelectRow} size="small" />
      </TableCell>
      <TableCell>{row?.baseAmount}</TableCell>
      <TableCell>{row?.vat}</TableCell>

      <TableCell>
        <TableMoreMenu
          open={anchorEl}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  handleShowTaxesModal();
                  handleCloseMenu();
                }}
              >
                <EditIcon
                  height={20}
                  width={20}
                  sx={{
                    height: "auto",
                    width: "auto",
                  }}
                  customColor={theme.palette?.action.active}
                />
                {t("update_tax")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleShowDeleteTaxDialog();
                  handleCloseMenu();
                }}
              >
                <DeleteIcon
                  height={20}
                  width={20}
                  sx={{
                    height: "auto",
                    width: "auto",
                  }}
                  customColor={theme.palette?.action.active}
                />
                {t("delete_tax")}
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default TaxesTableRow;
