import {
  Box,
  Card,
  Container,
  Skeleton,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND } from "../../../constants/settings";
import { codeState } from "../../../features/pageSlice";
import { PostAsync } from "../../../common/httpRequests";
import CodeForm from "../form/CodeForm";
import EmailForm from "../form/EmailForm";
import EmailHeader from "../header/EmailHeader";
import svgImage from "../../../assets/logo/LoginBackground.png";

interface EmailPageContainerProps {
  fetchSupplierData: Function;
  allowed: boolean | "";
  setIsLoading: Function;
}

export default function EmailPageContainer(props: EmailPageContainerProps) {
  const { fetchSupplierData, allowed, setIsLoading } = props;
  const { t } = useTranslation();
  const [email, setEmail] = useState("placeholder@gmail.com");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const page = useSelector((state: any) => state.pageState.value);
  const theme = useTheme();

  useEffect(() => {
    const fetchAPIs = async () => {
      await fetchSupplierData();
    };
    fetchAPIs();
  }, []);

  const handleSendEmail = async (event) => {
    if (event.target["email"]) {
      event.preventDefault();
      var capturedEmail = event.target["email"].value;
      setEmail(capturedEmail);
    } else {
      capturedEmail = email;
    }
    const currentSupplierId = window.location.pathname.split("/")[1];
    const request = {
      id: currentSupplierId,
      email: capturedEmail,
    };
    setIsLoading(true);
    const res = await PostAsync(
      `${BACKEND}/api/vendorservice/suppliers/emailpasscode`,
      request
    );
    setIsLoading(false);
    if (res.status === 200 && event.target["email"]) {
      dispatch(codeState());
    } else if (res.status === 200) {
      return enqueueSnackbar(t("email.codeResent"), { variant: "success" });
    } else {
      return enqueueSnackbar(t("notFound"), {
        variant: "error",
      });
    }
  };

  function displayWarningIfSupplierNotFound() {
    if (allowed === "") {
      return (
        <Box display={"flex"} flexDirection={"column"} minWidth="500px">
          <Skeleton variant="text" width={410} height={40} />
          <Skeleton variant="text" width={210} height={40} />
          <Skeleton variant="text" width={500} height={30} />
          <Skeleton variant="text" width={60} height={30} />
          <Skeleton variant="text" width={"100%"} height={60} />
          <Skeleton variant="text" width={"100%"} height={60} />
        </Box>
      );
    } else if (!allowed) {
      return (
        <Box display={"flex"} flexDirection={"column"} maxWidth="500px">
          <Typography variant="h3" paddingBottom={2}>
            {t("notFound")}
          </Typography>
          <Typography gutterBottom>
            {t("notFoundctx")}{" "}
            <a
              href="mailto:support@mydost.ai"
              style={{
                textDecoration: "none",
                color: theme.palette.secondary.main,
              }}
            >
              support@mydost.ai
            </a>
          </Typography>
        </Box>
      );
    } else {
      return emailVerificationStep[page];
    }
  }

  const emailVerificationStep = {
    0: <EmailForm handleSendEmail={handleSendEmail} />,
    1: (
      <CodeForm
        email={email}
        handleSendEmail={handleSendEmail}
        setIsLoading={setIsLoading}
      />
    ),
  };

  return (
    <>
      <EmailHeader />
      <MainStyle>
        <RootStyle>
          <SectionStyle>
            <Box sx={{ width: "80%", marginTop: 25 }}>
              <Typography
                variant="body1"
                fontWeight={700}
                fontSize={40}
                color={"text.secondary"}
              >
                {t(`supplier_portal_tag_line`)}
              </Typography>
            </Box>
          </SectionStyle>
          <ContentStyle>{displayWarningIfSupplierNotFound()}</ContentStyle>
        </RootStyle>
      </MainStyle>
    </>
  );
}

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})(({ theme }) => ({
  flexGrow: 1,
  height: "100vh",

  [theme.breakpoints.up("lg")]: {
    width: `calc(100%)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    minHeight: "100%",
  },
}));

const SectionStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "50%",
  display: "flex",
  justifyContent: "center",
  backgroundImage: `url(${svgImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "99%",
}));

const ContentStyle = styled(Card)(({ theme }) => ({
  maxWidth: "100%",
  margin: "100px auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(4, 3, 0, 3),
  borderRadius: "32px",
  backgroundColor: theme.palette.greyShade.lighter,
}));
