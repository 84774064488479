import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LanguagePopover from "./LanguagePopover";
import LogoutButton from "./LogoutButton";
import { RootStyle } from "../../email_send_page/header/EmailHeader";
import { useTranslation } from "react-i18next";
import NewDostLogo from "../../../assets/logo/newDostLogo.png";

export default function SupplierHeader(props) {
  const { fetchSupplierData } = props;
  const [supplier, setSupplier] = useState("supplier");
  const mode = useSelector((state: any) => state.mode.value);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAPIs = async () => {
      let data = await fetchSupplierData();
      setSupplier(data?.name);
    };
    fetchAPIs();
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        border: 0,
        borderBottom: 2,
      }}
    >
      <RootStyle isCollapse={false} isOffset={true} verticalLayout={false}>
        <Toolbar
          sx={{
            bgcolor: "background.paper",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            sx={{
              width: "90px",
              mr: 2,
            }}
            alt="Dost logo"
            src={NewDostLogo}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "text.primary" }}
          >
            {t("supplier")}: {supplier}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <LanguagePopover />
            <LogoutButton />
          </Box>
        </Toolbar>
      </RootStyle>
    </Box>
  );
}
