import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import {
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, Dialog, DialogTitle } from "@mui/material";
import React from "react";
import { useForm, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { ItemsEntity } from "../../../../@types/createDocument";
import { useTranslation } from "react-i18next";
import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import CloseModalButton from "../../../../assets/icon/close/CloseModalButton";
import { useTheme } from "@mui/material";

interface ItemsModalProps {
  isShowModal: boolean;
  showModalMethod: VoidFunction;
  itemsAppend?: (obj: ItemsEntity) => void;
  itemUpdate?: (index: number, obj: ItemsEntity) => void;
  index?: number;
  initialValues?: ItemsEntity;
}

const ItemsModal: React.FC<ItemsModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    isShowModal,
    itemsAppend,
    showModalMethod,
    itemUpdate,
    index,
    initialValues,
  } = props;

  const itemsSchema = Yup.object().shape({
    referenceCode: Yup.string().required(
      t("referenceCode_is_required") as string
    ),
    description: Yup.string().required(t("description_is_required") as string),
    unitPrice: Yup.number()
      .required(t("unitPrice_is_required") as string)
      .typeError(t("unitPrice_must_be_number") as string),
    quantity: Yup.number()
      .required(t("quantity_is_required") as string)
      .typeError(t("quantity_must_be_number") as string),

    vat: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    discount: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    deliveryNote: Yup.string(),
    poNumber: Yup.string(),
  });

  const methods = useForm<ItemsEntity>({
    resolver: yupResolver(itemsSchema),
    defaultValues: initialValues,
  });

  const theme = useTheme();

  const { handleSubmit } = methods;

  const addItem = (values: ItemsEntity) => {
    index === undefined ? itemsAppend!(values) : itemUpdate!(index, values);
    showModalMethod();
  };

  const subSection = {
    pb: 2,
    pt: 1,
    pr: 2,
    mt: 2.5,
    ml: 0.5,
    borderRadius: "8px",
    background: theme.palette.background.neutral,
  };
  return (
    <Dialog
      open={isShowModal}
      onClose={showModalMethod}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={"md"}
      fullWidth
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(addItem)}>
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" sx={{ textAlign: "left", my: 1 }}>
              {t(`add_item`)}
            </Typography>

            <IconButton
              sx={{
                height: "10px",
                width: "8px",
              }}
              onClick={showModalMethod}
            >
              <CloseModalButton />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid container spacing={2} item xs={12} sx={subSection}>
              <Grid item xs={6}>
                <RHFTextField
                  label={t("referenceCode")}
                  name={"referenceCode"}
                />
              </Grid>
              <Grid item xs={6}>
                <RHFTextField label={t("description")} name={"description"} />
              </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sx={subSection}>
              <Grid item xs={6}>
                <RHFTextField name={"unitPrice"} label={t("unitPrice")} />
              </Grid>
              <Grid item xs={6}>
                <RHFTextField name={"quantity"} label={t("quantity")} />
              </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sx={subSection}>
              <Grid item xs={6}>
                <RHFTextField name={"deliveryNote"} label={t("deliveryNote")} />
              </Grid>

              <Grid item xs={6}>
                <RHFTextField name={"poNumber"} label={t("poNumber")} />
              </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sx={subSection}>
              <Grid item xs={6}>
                <RHFTextField name={"vat"} label={t("tax_amount_not_%")} />
              </Grid>

              <Grid item xs={6}>
                <RHFTextField name={"discount"} label={t("discount")} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            {index === undefined ? t("add_item") : t("update_item")}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default ItemsModal;
