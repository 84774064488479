import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CloseModalButton from "../../../assets/icon/close/CloseModalButton";
import CreateDocumentForm from "./CreateDocumentForm";

interface CreateDocumentProps {
  getDocuments: () => Promise<void>;
  showModalMethod: VoidFunction;
}

const CreateDocument: React.FC<CreateDocumentProps> = (props) => {
  const { getDocuments, showModalMethod } = props;
  const { t } = useTranslation();
  return (
    <Box>
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">{t("create_document")}</Typography>
          <IconButton sx={{ height: 30, width: 30 }} onClick={showModalMethod}>
            <CloseModalButton />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <CreateDocumentForm {...{ getDocuments, showModalMethod }} />
      </DialogContent>
    </Box>
  );
};

export default CreateDocument;
