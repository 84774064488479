const es = {
  notFound: "Proveedor no fue encontrado",
  notFoundctx:
    "Por favor, acceda al portal con en enlace enviado via email. Si sigues viendo esta mensaje, por favor entra en contacto con nosotros via",
  supplier_portal_tag_line: `Recopile y procese sus documentos automáticamente garantizando una ejecución sin errores`,
  supplierDeleted:
    "Proveedor borrado. Compruebe tu enlace o tus autorizaciones",
  step1: "Paso 1. Selecciona",
  step1ctx: "Selecciona facturas de tus archivos.",
  step2: "Paso 2. Revisa",
  step2ctx: "Revisa si las facturas correctas fueron seleccionadas.",
  step3: "Paso 3. Carga",
  step3ctx: "Carga las facturas.",
  login: "Inicia Sesión",
  drop: "Arrastra o selecciona documentos.",
  dropctx1: "Arrastra archivos o pulsa",
  dropctx2: "explorar",
  dropctx3: "para seleccionar",
  selected: "Documentos seleccionados:",
  removeAll: "Eliminar Todo",
  upload: "Cargar documentos",
  useDedicatedLink:
    "No puedes cargar sin usar tu enlace dedicado. Por favor, utilice el enlace que le hemos enviado por correo eletronico.",
  duplicateNotAllowed: "Archivos duplicados no permitidos",
  limit: "Límite de 10 archivos.",
  successUpload: "Documento(s) cargado(s) exitosamente",
  errorUpload: "Error cargandos archivos: ",
  logout: "Cerrar sesión",
  email: {
    enterEmail: "Introduzca tu email para recibir un código de confirmación",
    enterEmailCtx:
      "Eso ayuda a asegurarnos de que eres tú antes de permitir cargas",
    sendCode: "Enviar código",
    checkEmail: "Solicitud enviada con éxito!",
    checkEmailCtx1: "Hemos enviado un código de 6 dígitos para ",
    checkEmailCtx2:
      ". Por favor, introduzca el código en el hueco de abajo para verificar tu email.",
    confirm: "Confirmar",
    code: "Código",
    resend: "No has recibido?",
    resendLink: "Volver a enviar",
    codeResent: "Código fue enviado otra vez a tu email",
    wrongCode: "Código incorrecto",
  },
  loadingSupplier: "Cargando proveedor...",
  NeedReview: "Necesita revisión",
  Duplicate: "Duplicado",
  Default: "En progreso",
  Extracted: "Extraído",
  Failed: "Fallido",
  Error: "Error",
  Reviewed: "Revisado",
  "Add Document": "Añadir Documento",
  "Created on": "Creado en",
  Status: "Estado",
  Date: "Fecha",
  Message: "Mensaje",
  Close: "Cerca",

  "Document name": "Nombre del documento",
  Paid: "Pagado",
  UnPaid: "No pagado",
  Approved: "Aprobado",
  Rejected: "Rechazado",
  "Payment status": "Estado de pago",
  "ERP status": "Estado ERP",
  All: "Todos",
  Documents: "Documentos",
  "Erp status": "Estado ERP",
  Actions: "Acciones",
  "Created in ERP": "Creado en ERP",
  Yes: "Si",
  No: "No",
  Pending: "Pendiente",
  "View ERP details": "Ver detalles ERP",
  "ERP Details": `Detailles ERP`,
  "View payment details": "Ver detalles de pago",
  "Payment Details": "Detalles del pago",
  "Payment Date": "Fecha de pago",
  "Transaction method": "Método de transacción",
  "Rows per page": "Filas por página",
  of: "de",
  a_maximum_of_10_files_are_allowed_in_a_single_upload: `Se permite un máximo de 10 archivos en una sola carga`,
  upload_by_device: "Subir por dispositivo",
  select_language: "Seleccionar idioma",
  no_data: `Sin Datos`,
  supplier: `Proveedor`,
  add_a_new_document: `Agregar un nuevo documento`,
  entries_per_page: `Entradas por página`,
  "Drop your files here or browse through your machine.": `Suelte sus archivos aquí o navegue por su máquina.`,
  browse: `Navegar`,
  upload_documents: `Subir documentos`,
  upload_from_device: `Subir desde el dispositivo`,
  // Create Document
  create_document: `Crear documento`,
  document_information: `Información del documento`,
  account_class: `Clase de cuenta`,
  account_payable: `Cuenta por pagar`,
  document_class: `Clase de documento`,
  deliveryNote: `Albarán de entrega`,
  invoice: `Factura`,
  documentNumber: `Número de documento`,
  documentDate: `Fecha del documento`,
  add_valid_date: `Agregar fecha válida`,
  documentClass_is_required: `Se requiere clase de documento`,
  documentNumber_is_required: `El número de documento es obligatorio`,
  poNumber_is_required: `Se requiere el número de orden de compra`,
  totalAmount: `Monto total`,
  taxPercentage: `Porcentaje de impuesto`,
  customer_information: `Información al cliente`,
  customerCompanyName: `Nombre de la empresa del cliente`,
  customerCompanyID: `ID de la empresa del cliente`,
  customerCompanyAddress: `Dirección de la empresa del cliente`,
  customerEmail: `Correo electrónico del cliente`,
  customerCompanyName_is_required: `El nombre de la empresa del cliente es obligatorio`,
  customerCompanyID_is_required: `Se requiere el ID de la empresa del cliente`,
  customerCompanyAddress_is_required: `Se requiere la dirección de la empresa del cliente`,
  customerEmail_is_required: `El correo electrónico del cliente es obligatorio`,
  document_is_required: `El documento es requerido`,
  account_information: `Información de la cuenta`,
  vendor_information: `Informacion del proveedor`,
  items_information: `Información de los artículos`,
  taxes_information: `Información de impuestos`,
  optional: `Opcional`,
  account_receivable: `cuenta por cobrar`,
  accountClass_is_required: `Se requiere clase de cuenta`,
  back: `Atrás`,
  next: `Próximo`,
  invoiceID: `ID de factura`,
  poNumber: `Numero postal`,
  totalWithoutTax: `Total (sin impuestos)`,
  totalTax: `Total impuestos`,
  invoiceDate: `Fecha de la factura`,
  dueDate: `Fecha de vencimiento`,
  invoiceId_is_required: `Se requiere identificación de factura`,
  totalWithoutTax_is_required: `Se requiere total sin impuestos`,
  totalTax_is_required: `Se requiere el impuesto total`,
  totalAmount_is_required: `Se requiere cantidad total`,
  taxPercentage_is_required: `Se requiere porcentaje de impuesto`,
  deliveryNote_is_required: `Se requiere albarán de entrega`,
  invoiceDate_is_required: `Se requiere fecha de factura`,
  documentDate_is_required: `Se requiere fecha del documento`,
  dueDate_is_required: `Se requiere fecha de vencimiento`,
  vendorCompanyName: `Nombre de la empresa proveedora`,
  vendorCompanyID: `ID de la empresa proveedora`,
  vendorCompanyAddress: `Dirección de la empresa proveedora`,
  vendorCompanyName_is_required: `Se requiere el nombre de la empresa proveedora`,
  vendorCompanyID_is_required: `Se requiere el ID de la empresa proveedora`,
  vendorCompanyAddress_is_required: `Se requiere la dirección de la empresa proveedora`,
  referenceCode: `Código de referencia`,
  unit: `Unidad`,
  unitPrice: `Precio unitario`,
  vatPercentage: `IVA %`,
  vat: `IVA`,
  dtoPercentage: `DTO %`,
  dto: `DTO`,
  discountPercentage: `% de descuento`,
  discount: `Descuento`,
  subTotalAmount: `Monto subtotal`,
  referenceCode_is_required: `Se requiere código de referencia`,
  description_is_required: `Se requiere descripción`,
  unit_is_required: `Se requiere unidad`,
  unitPrice_is_required: `Se requiere precio unitario`,
  quantity_is_required: `Se requiere cantidad`,
  vatPercentage_is_required: `Se requiere porcentaje de IVA`,
  vat_is_required: `Se requiere IVA`,
  dtoPercentage_is_required: `Se requiere porcentaje de DTO`,
  dto_is_required: `Se requiere DTO`,
  discountPercentage_is_required: `Se requiere porcentaje de descuento`,
  discount_is_required: `Se requiere descuento`,
  subTotalAmount_is_required: `Se requiere el monto subtotal`,
  amount_is_required: `Se requiere cantidad`,
  delete_item: `Eliminar elemento`,
  update_item: `Actualizar elemento`,
  delete_items: `Eliminar objetos`,
  taxes: `Impuestos`,
  baseAmount: `Cantidad base`,
  add_tax: `Agregar impuesto`,
  submit: `Someter`,
  reset: `Reiniciar`,
  atleast_one_item_is_required: `Se requiere al menos un artículo`,
  atleast_one_tax_is_required: `Se requiere al menos un impuesto`,
  submit_and_create_new_document: `Enviar y crear un nuevo documento`,
  baseAmount_is_required: `Se requiere cantidad base`,
  vendorEmailAddress: `Dirección de correo electrónico del proveedor`,
  add_item: `Añadir artículo`,
  vendorEmail_is_required: `Se requiere la dirección de correo electrónico del proveedor`,
  email_invalid: `El correo electrónico debe ser una dirección de correo electrónico válida`,
  quantity: `Cantidad`,
  amount: `Cantidad`,
  actions: `Acciones`,
  description: `Descripción`,
  delete_approval: `Estás seguro de que quieres eliminar esto`,
  cancel_button: `Cancelar`,
  delete: `Eliminar`,
  delete_tax: `Eliminar impuesto`,
  delete_taxes: `Eliminar impuestos`,
  only_one_document_is_allowed_to_upload: `Sólo se permite cargar un documento`,
  upload_document: `Subir documento`,
  tax: `Impuesto`,
  document_created_successfully: `Documento creado exitosamente`,
  update_tax: `Actualizar impuesto`,
  my_information: `Mi informacion`,
  emailAddress: `Dirección de correo electrónico`,
  companyAddress: `Dirección de empresa`,
  companyID: `ID de empresa`,
  companyName: `El nombre de empresa`,
  companyName_is_required: `El nombre de la empresa es obligatorio`,
  companyID_is_required: `Se requiere el ID de la empresa`,
  companyAddress_is_required: `La dirección de la empresa es obligatoria`,
  email_is_required: `Se requiere dirección de correo electrónico`,
  dueDate_should_be_greater_than_documentDate: `La fecha de vencimiento debe ser mayor que la fecha del documento`,
  documentDate_should_be_less_than_dueDate: `La fecha del documento debe ser menor que la fecha de vencimiento`,
  "tax_amount_not_%": "Impuesto (monto)",
  document_details: `Detalles del documento`,
  date_details: `Detalles de la fecha`,
  logo_url: `URL del logotipo`,
  please_enter_your_information: `Por favor ingresa tu información`,
  please_enter_customer_information: `Detalles del cliente a quién se enviará este documento`,
  "Approval Process": `Proceso de aprobación`,
  tax_must_be_number: `Se requiere el impuesto y debe ser numérico`,
  discount_must_be_number: `El descuento debe ser un número`,
  unitPrice_must_be_number: `El precio unitario es obligatorio y debe ser un número`,
  quantity_must_be_number: `La cantidad es obligatoria y debe ser un número`,
  baseAmount_must_be_number: `Se requiere un monto base y debe ser un número`,
  tax_is_required: `Se requiere impuesto`,
  upload_deliveryNote: `Subir nota de entrega`,
  upload_invoice: `Subir factura`,
};

export default es;
