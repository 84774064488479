const en = {
  notFound: "Supplier not found",
  notFoundctx:
    "Please use the link provided to you via email. In case you still see this message please contact us at",
  supplierDeleted: "Supplier deleted. Check link or authorizations.",
  supplier_portal_tag_line: `Collect and process your documents automatically ensuring error free execution`,
  step1: "Step 1. Select",
  step1ctx: "Select invoices from your files.",
  step2: "Step 2. Check",
  step2ctx: "Check if the correct invoices were selected.",
  step3: "Step 3. Upload",
  step3ctx: "Upload the invoices selected.",
  login: "Login",
  drop: "Drop or select documents.",
  dropctx1: "Drop documents from your computer",
  dropctx2: "or browse",
  dropctx3: "to select",
  selected: "Selected documents:",
  removeAll: "Remove All",
  upload: "Upload documents",
  duplicateNotAllowed: "Duplicate files are not allowed",
  limit: "Limit of 10 documents.",
  successUpload: "Document(s) uploaded successfully",
  errorUpload: "Error uploading documents: ",
  useDedicatedLink:
    "You cannot upload without using your dedicated url. Please use the link we have sent you through email.",
  logout: "Logout",
  email: {
    enterEmail: "Enter your email to receive a confirmation code",
    enterEmailCtx:
      "This helps us to make sure it's you before allowing uploads",
    sendCode: "Send code",
    checkEmail: "Request sent successfully!",
    checkEmailCtx1: "We have sent a 6-digit confirmation code to ",
    checkEmailCtx2: ". Please enter the code below to verify your email.",
    confirm: "Confirm",
    code: "Code",
    resend: "Don't have a code?",
    resendLink: "Resend code",
    codeResent: "Code was resent to your email",
    wrongCode: "Wrong code entered",
  },
  loadingSupplier: "Loading supplier...",
  NeedReview: "Need review",
  Default: "In progress",
  Extracted: "Extracted",
  Failed: "Failed",
  Error: "Error",
  Reviewed: "Reviewed",
  Duplicate: "Duplicate",
  "Add Document": "Add Document",
  "Created on": "Created on",
  Status: "Status",
  Date: "Date",
  Message: "Message",
  Close: "Close",
  "Document name": "Document name",
  Paid: "Paid",
  UnPaid: "Un paid",
  Approved: "Approved",
  Rejected: "Rejected",
  "Payment status": "Payment status",
  "ERP status": "ERP status",
  All: "All",
  Documents: "Documents",
  "Erp status": "Erp status",
  Actions: "Actions",
  "Created in ERP": "Created in ERP",
  Yes: "Yes",
  No: "No",
  Pending: "Pending",
  "View ERP details": "View ERP details",
  "ERP Details": `ERP Details`,
  "View payment details": "View payment details",
  "Payment Details": "Payment Details",
  "Payment Date": "Payment Date",
  "Transaction method": "Transaction method",
  "Rows per page": "Rows per page",
  of: "of",
  upload_by_device: "Upload by device",
  a_maximum_of_10_files_are_allowed_in_a_single_upload: `A maximum of 10 files are allowed in a single upload`,
  select_language: "Select language",
  no_data: `No Data`,
  supplier: `Supplier`,
  add_a_new_document: `Add a new Document`,
  entries_per_page: `Entries per page`,
  "Drop your files here or browse through your machine.": `Drop your files here or browse through your machine.`,
  browse: `Browse`,
  upload_documents: `Upload documents`,
  upload_from_device: `Upload from device`,
  deliveryNote: `Delivery note`,
  invoice: `Invoice`,
  // Create Document
  add_valid_date: `Add valid date`,
  totalAmount: `Total amount`,
  customerCompanyName: `Customer company name`,
  customerCompanyID: `Customer company ID`,
  customerCompanyAddress: `Customer company address`,
  customerEmail: `Customer email`,
  customerCompanyName_is_required: `Customer company name is required`,
  customerCompanyID_is_required: `Customer company ID is required`,
  customerCompanyAddress_is_required: `Customer company address is required`,
  customerEmail_is_required: `Customer Email is required`,
  create_document: `Create document`,
  account_information: `Account information`,
  document_information: `Document information`,
  customer_information: `Customer information`,
  vendor_information: `Vendor information`,
  items_information: `Items information`,
  taxes_information: `Taxes information`,
  account_class: `Account class`,
  document_class: `Document class`,
  optional: `Optional`,
  account_receivable: `Account receivable`,
  account_payable: `Account payable`,
  accountClass_is_required: `Account class is required`,
  documentClass_is_required: `Document class is required`,
  back: `Back`,
  next: `Next`,
  invoiceID: `Invoice ID`,
  documentNumber: `Document number`,
  poNumber: `PO number`,
  totalWithoutTax: `Total (without tax)`,
  totalTax: `Total tax`,
  taxPercentage: `Tax percentage`,
  documentDate: `Document date`,
  dueDate: `Due date`,
  invoiceId_is_required: `Invoice Id is required`,
  documentNumber_is_required: `Document number is required`,
  poNumber_is_required: `PO number is required`,
  totalWithoutTax_is_required: `Total without tax is required`,
  totalTax_is_required: `Total tax is required`,
  totalAmount_is_required: `Total amount is required`,
  taxPercentage_is_required: `Tax percentage is required`,
  deliveryNote_is_required: `Delivery note is required`,
  documentDate_is_required: `Document date is required`,
  dueDate_is_required: `Due date is required`,
  vendorCompanyName: `Vendor company name`,
  vendorCompanyID: `Vendor company ID`,
  vendorCompanyAddress: `Vendor company address`,
  vendorCompanyName_is_required: `Vendor company name is required`,
  vendorCompanyID_is_required: `Vendor company ID is required`,
  vendorCompanyAddress_is_required: `Vendor company address is required`,
  referenceCode: `Reference code`,
  unit: `Unit`,
  unitPrice: `Unit price`,
  vatPercentage: `VAT %`,
  vat: `VAT`,
  dtoPercentage: `DTO %`,
  dto: `DTO`,
  discountPercentage: `Discount %`,
  discount: `Discount`,
  subTotalAmount: `Subtotal amount`,
  referenceCode_is_required: `Reference code is required`,
  description_is_required: `Description is required`,
  unit_is_required: `Unit is required`,
  unitPrice_is_required: `Unit price is required`,
  quantity_is_required: `Quantity is required`,
  vatPercentage_is_required: `VAT percentage is required`,
  vat_is_required: ` VAT is required`,
  dtoPercentage_is_required: `DTO percentage is required`,
  dto_is_required: `DTO is required`,
  discountPercentage_is_required: ` Discount percentage is required`,
  discount_is_required: `Discount is required`,
  subTotalAmount_is_required: `Sub total amount is required`,
  amount_is_required: `Amount is required`,
  delete_item: `Delete item`,
  update_item: `Update item`,
  delete_items: `Delete items`,
  taxes: `Taxes`,
  baseAmount: `Base amount`,
  add_tax: `Add tax`,
  submit: `Submit`,
  reset: `Reset`,
  atleast_one_item_is_required: `Atleast one item is required`,
  atleast_one_tax_is_required: `Atleast one tax is required`,
  submit_and_create_new_document: `Submit and create new document`,
  baseAmount_is_required: `Base amount is required`,
  vendorEmailAddress: `Vendor email address`,
  vendorEmail_is_required: `Vendor email address is required`,
  add_item: `Add item`,
  delete_approval: `Are you sure you want to delete this`,
  upload_document: `Upload document`,
  document_is_required: `Document is required`,
  email_invalid: `Email must be a valid email address`,
  quantity: `Quantity`,
  amount: `Amount`,
  actions: `Actions`,
  description: `Description`,
  cancel_button: `Cancel`,
  delete: `Delete`,
  tax: `Tax`,
  delete_tax: `Delete tax`,
  delete_taxes: `Delete taxes`,
  only_one_document_is_allowed_to_upload: `Only one document allowed to be upload`,
  document_created_successfully: `Document created successfully`,
  update_tax: `Update tax`,
  my_information: `My information`,
  emailAddress: `Email address`,
  companyAddress: `Company address`,
  companyID: `Company ID`,
  companyName: `Company name`,
  companyName_is_required: `Company name is required`,
  companyID_is_required: `Company ID is required`,
  companyAddress_is_required: `Company address is required`,
  email_is_required: `Email address is required`,
  dueDate_should_be_greater_than_documentDate: `Due date should be greater than document date`,
  documentDate_should_be_less_than_dueDate: `Document date should be less than due date`,
  "tax_amount_not_%": "Tax (amount)",
  document_details: `Document Details`,
  date_details: `Date Details`,
  logo_url: `Logo URL`,
  please_enter_your_information: `Please enter your information`,
  please_enter_customer_information: `Please enter information of your customer who will receive this document.`,
  "Approval Process": `Approval process`,
  tax_must_be_number: `Tax is required and must be number`,
  discount_must_be_number: `Discount must be number`,
  unitPrice_must_be_number: `Unit price is required and must be number`,
  quantity_must_be_number: `Quantity is required and must be number`,
  baseAmount_must_be_number: `Base amount is required and must be number`,
  tax_is_required: `Tax is required`,
  upload_deliveryNote: `Upload delivery note`,
  upload_invoice: `Upload invoice`,
};

export default en;
