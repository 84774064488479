import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { TablePaginationProps } from "@mui/material";
import { useTheme } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "../../../assets/icon/chevron/right";
import ChevronLeftIcon from "../../../assets/icon/chevron/left";
import { NewTextFormField } from "../../../components/form/TextField";

type MyTablePaginationProps = Omit<
  TablePaginationProps,
  "onPageChange" | "onRowsPerPageChange"
> & {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: any, newPage: number) => void;
  onRowsPerPageChange: (event: SelectChangeEvent<HTMLInputElement>) => void;
};
const MyTablePagination = ({ ...props }: MyTablePaginationProps) => {
  const { count, page, rowsPerPage, onPageChange, onRowsPerPageChange } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(event, newPage);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<any>) => {
    onRowsPerPageChange(event);
  };
  const theme = useTheme();
  const { palette } = theme;
  const iconColor = palette?.grey["600"];
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexWrap: "wrap",
        border: "1px solid #E0E0E0",
        borderTop: "none",
        padding: 1,
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          // justifyContent: "flex-end",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "8px",
          p: 1,
        }}
      >
        <Typography variant="subtitle2">{t("entries_per_page")}:</Typography>
        <Select
          id="customized-textbox"
          displayEmpty
          value={rowsPerPage}
          input={<NewTextFormField />}
          sx={{
            "& .MuiInputBase-input": {
              backgroundColor: "background.paper",
              padding: "7px 26px 7px 10px",
              height: "32px",
              display: "flex",
              alignItems: "center",
            },
          }}
          onChange={handleChangeRowsPerPage}
        >
          <MenuItem value="5">{5}</MenuItem>
          <MenuItem value="10">10</MenuItem>
          <MenuItem value="25">25</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
        </Select>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& svg": { m: 1.5 },
          }}
        >
          <Typography variant="subtitle2">
            {page * rowsPerPage + 1}-
            {page * rowsPerPage + rowsPerPage > count
              ? count
              : page * rowsPerPage + rowsPerPage}{" "}
            {t("of")}&nbsp;
            {count}
          </Typography>
          <Tooltip title={t("previous_page")}>
            <IconButton
              size="small"
              disabled={page === 0}
              sx={{
                marginRight: 1,
                marginLeft: 1,
                height: "30px",
                width: "30px",
                color: palette?.grey["400"],
                bgcolor: palette?.grey["300"],
                cursor: "pointer",
                "&:hover": { color: "black" },
                "&:disabled": {
                  color: palette?.grey["400"],
                  bgcolor: palette?.grey["300"],
                },
              }}
              onClick={(e) => handleChangePage(e, page - 1)}
            >
              <ChevronLeftIcon
                height={18}
                width={18}
                color={
                  page === 0
                    ? palette?.action.disabled
                    : theme.palette.primary.light
                }
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("next_page")}>
            <IconButton
              size="small"
              disabled={page * rowsPerPage + rowsPerPage >= count}
              sx={{
                height: "30px",
                width: "30px",
                color: iconColor,
                bgcolor: palette?.grey["300"],
                cursor: "pointer",
                "&:hover": { color: "black" },
                "&:disabled": {
                  color: palette?.grey["400"],
                  bgcolor: palette?.grey["300"],
                },
              }}
              onClick={(e) => handleChangePage(e, page + 1)}
            >
              <ChevronRightIcon
                height={18}
                width={18}
                color={
                  page * rowsPerPage + rowsPerPage >= count
                    ? palette?.action.disabled
                    : theme.palette.primary.light
                }
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default MyTablePagination;
