import {
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  Typography,
  TableContainer,
  TableCell,
  TableRow,
  Table,
  TableBody,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import CloseModalButton from "../../../../assets/icon/close/CloseModalButton";

const PaymentModal = ({ open, handleClose, selectedRow }) => {
  const theme = useTheme();
  const [tableDetails, setTableDetails] = useState<any[]>([]);

  useEffect(() => {
    let tableData = [
      { key: t("Document name"), value: selectedRow?.fileName },
      { key: t("Status"), value: t(selectedRow?.paymentDetail?.status) },
      {
        key: t("Payment Date"),
        value: format(
          new Date(selectedRow?.paymentDetail?.transactionDate ?? 0),
          "dd MMMM yyyy"
        ),
      },
      {
        key: t("Transaction method"),
        value: t(selectedRow?.paymentDetail?.transactionMethod),
      },
    ];
    if (selectedRow?.paymentDetail?.customProperties) {
      Object.entries(selectedRow?.paymentDetail?.customProperties)?.map(
        (item) => {
          tableData.push({ key: item[0], value: item[1] as string });
        }
      );
    }
    setTableDetails(tableData);
  }, [selectedRow]);
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      sx={{
        "& .MuiDialogContent-root": {
          padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
          padding: theme.spacing(1),
        },
      }}
    >
      <DialogTitle id="customized-dialog-title">
        {t("Payment Details")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        size="small"
        sx={{
          position: "absolute",
          right: 24,
          top: 24,
          width: 10,
          height: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseModalButton />
      </IconButton>
      <DialogContent dividers>
        <TableContainer sx={{ maxHeight: 440, p: 0, m: 0 }}>
          <Table
            sx={{
              p: 0,
              m: 0,
            }}
          >
            <TableBody
              sx={{
                p: 0,
                m: 0,
              }}
            >
              {tableDetails?.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{
                      p: 0,
                      pl: 0,
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        p: 1,
                        pl: 0,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                      >
                        {t(row.key)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "rgb(99, 115, 129)",
                        }}
                      >
                        {row.value}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="contained"
          color="primary"
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentModal;
