import {
  Box,
  Button,
  Container,
  Dialog,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SupplierHeader from "./header/SupplierHeader";
import UploadDocumentBox from "./upload_box/UploadDocumentBox";
import ColumnGroupingTable from "./components/table/Table";
import { GetAsync } from "../../common/httpRequests";
import { BACKEND } from "../../constants/settings";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { SupplierDocuments } from "../../@types/documents";
import { useSnackbar } from "notistack";
import ChevronDown from "../../assets/icon/chevron/ChevronDown";
import { UploadIcon } from "../../assets/icon/Upload";
import { DocumentIcon } from "../../assets/icon/Document";
import CreateDocument from "./createDocument";
import useInterval from "../../hooks/useInterval";
import ChevronRightIcon from "../../assets/icon/chevron/right";

export interface Controller {
  page: number;
  rowsPerPage: number;
  erpStatus: string;
  paymentStatus: string;
}

interface StateProps {
  loading: boolean;
  documents: SupplierDocuments;
}

export default function SupplierPageContainer(props) {
  const { allowed, fetchSupplierData } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [state, setState] = useState<StateProps>({
    loading: false,
    documents: {} as SupplierDocuments,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [controller, setController] = useState<Controller>({
    page: 0,
    rowsPerPage: 10,
    erpStatus: "",
    paymentStatus: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [docType, setDocType] = useState("");
  const [isUploadDocumentModalVisible, setIsUploadDocumentModalVisible] =
    useState(false);
  const [isCreateDocumentModalVisible, setIsCreateDocumentModalVisible] =
    useState(false);
  const [showUploadOptions, setShowUploadOptions] =
    useState<HTMLButtonElement | null>(null);

  const openUploadOptions = Boolean(showUploadOptions);

  const handleOpenUploadOptions = (event) =>
    setShowUploadOptions(event.currentTarget);

  const handleCloseUploadOptions = () => setShowUploadOptions(null);

  const getDocuments = async () => {
    const currentSupplierId = window.location.pathname.split("/")[1];
    GetAsync(
      `${BACKEND}/api/vendorservice/suppliers/${currentSupplierId}/documents?page=${
        controller?.page + 1
      }&record=${controller?.rowsPerPage}`,
      {
        supplierId: currentSupplierId,
        code: localStorage.getItem("code"),
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          setState({ ...state, loading: false, documents: res?.data });
        } else {
          enqueueSnackbar(t(res?.data?.Detail), { variant: "error" });
          setState({ ...state, loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loading: false });
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const showModalMethod = () => setIsModalVisible(!isModalVisible);

  function renderUploadIfAllowed() {
    if (allowed) {
      if (isUploadDocumentModalVisible) {
        return (
          <UploadDocumentBox {...{ getDocuments, showModalMethod, docType }} />
        );
      } else if (isCreateDocumentModalVisible) {
        return <CreateDocument {...{ getDocuments, showModalMethod }} />;
      }
    } else {
      return (
        <Box
          borderRadius={"16px"}
          display="flex"
          alignItems="center"
          padding="24px"
        >
          {t("useDedicatedLink")}
        </Box>
      );
    }
  }

  useInterval(() => getDocuments(), 10000, []);

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    getDocuments();
  }, [controller]);

  const { documents, loading } = state;

  return (
    <Box>
      <SupplierHeader fetchSupplierData={fetchSupplierData} />
      <Dialog
        open={isModalVisible}
        scroll="body"
        maxWidth="lg"
        fullWidth
        sx={{
          borderRadius: "8px",
        }}
      >
        {renderUploadIfAllowed()}
      </Dialog>
      <Container
        maxWidth="xl"
        sx={{
          px: 30,
          py: 10,
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">{t("Documents")}</Typography>
          {documents.enableDocumentCreateThroughForm ? (
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<ChevronDown customColor="white" />}
            >
              {t(`upload_documents`)}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                setIsModalVisible(!isModalVisible);
                setIsUploadDocumentModalVisible(true);
              }}
            >
              {t("upload_document")}
            </Button>
          )}

          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              // onClick={() => {
              //   handleClose();
              // setIsModalVisible(!isModalVisible);
              // setIsCreateDocumentModalVisible(false);
              // setIsUploadDocumentModalVisible(true);

              // }}
              onClick={(event) => {
                handleOpenUploadOptions(event);
              }}
            >
              <UploadIcon width={20} height={20} />
              <Typography
                variant="subtitle1"
                color={theme.palette.action.active}
                sx={{ marginLeft: 0.5 }}
              >
                {t("upload_from_device")}
              </Typography>
              {showUploadOptions ? (
                <ChevronDown
                  customColor={theme.palette.action.active}
                  height={20}
                  width={20}
                />
              ) : (
                <ChevronRightIcon height={20} width={20} />
              )}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setIsModalVisible(!isModalVisible);
                setIsUploadDocumentModalVisible(false);
                setIsCreateDocumentModalVisible(true);
              }}
            >
              <DocumentIcon width={20} height={20} />
              <Typography
                variant="subtitle1"
                color={theme.palette.action.active}
                sx={{ marginLeft: 0.5 }}
              >
                {t(`create_document`)}
              </Typography>
            </MenuItem>
          </Menu>

          <Popover
            open={openUploadOptions}
            anchorEl={showUploadOptions}
            onClose={handleCloseUploadOptions}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuList dense>
              <MenuItem
                onClick={() => {
                  setDocType("Invoice");
                  handleClose();
                  setIsModalVisible(!isModalVisible);
                  setIsCreateDocumentModalVisible(false);
                  setIsUploadDocumentModalVisible(true);
                  handleCloseUploadOptions();
                }}
              >
                <UploadIcon
                  height={20}
                  width={20}
                  sx={{
                    height: "auto",
                    width: "auto",
                  }}
                />
                <ListItemText sx={{ ml: 1 }}>
                  {t("upload_invoice")}
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDocType("DeliveryNote");
                  handleClose();
                  setIsModalVisible(!isModalVisible);
                  setIsCreateDocumentModalVisible(false);
                  setIsUploadDocumentModalVisible(true);
                  handleCloseUploadOptions();
                }}
              >
                <UploadIcon
                  height={20}
                  width={20}
                  sx={{
                    height: "auto",
                    width: "auto",
                  }}
                />
                <ListItemText sx={{ ml: 1 }}>
                  {t("upload_deliveryNote")}
                </ListItemText>
              </MenuItem>
            </MenuList>
          </Popover>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "left",
            mb: 1,
            py: 1,
            gap: 2,
          }}
        >
          <Box>
            <Select
              labelId="ErpStatus"
              value={erpStatus}
              label={t("ERP status")}
              size="small"
              input={<NewTextField label={t("ERP status")} />}
              sx={{
                width: "200px",
              }}
              onChange={(e) => {
                setErpStatus(e.target.value);
              }}
              displayEmpty
            >
              <MenuItem value={""}>{t("All")}</MenuItem>
              <MenuItem value={"0"}>{t("Approved")}</MenuItem>
              <MenuItem value={"1"}>{t("Rejected")}</MenuItem>
            </Select>
          </Box>
          <Box>
            <Select
              value={paymentStatus}
              size="small"
              sx={{
                width: "200px",
              }}
              label={t("Payment Status")}
              input={<NewTextField label={t("Payment Status")} />}
              onChange={(e) => {
                setPaymentStatus(e.target.value);
              }}
              displayEmpty
            >
              <MenuItem value={""}>{t("All")}</MenuItem>
              <MenuItem value={"0"}>{t("Paid")}</MenuItem>
              <MenuItem value={"1"}>{t("UnPaid")}</MenuItem>
            </Select>
          </Box>
        </Box> */}
        <ColumnGroupingTable
          rows={documents?.documents}
          isLoading={loading}
          controller={controller}
          setController={setController}
          totalRecords={documents?.totalRecords}
        />
      </Container>
    </Box>
  );
}
